import { useCallback, useEffect, useMemo, useRef } from "react";
import { Logger } from "aws-amplify";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
  type MRT_RowVirtualizer,
} from "material-react-table";
import { useTheme } from "@mui/material/styles";

// mui components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAppState from "../../store/appState";
import useServerStateMutations, {
  useDemoConfig,
  useOrgScenarios,
  useScenarioStoreInfinite,
} from "../../store/serverState";
import type { Scenario, OrgScenario } from "../../types/API";
import { getDefaultMRTOptions } from "../Tables/MRT_Factories";
import Box from "@mui/system/Box";

// Global functions, constants and variables
const logger = new Logger("ScenarioStore", "INFO");
const scenarioColumnHelper = createMRTColumnHelper<Scenario>();

const scenarioColumns = [
  scenarioColumnHelper.accessor((row) => row["name"], {
    header: "Name",
    id: "name",
    filterVariant: "text",
  }),
  scenarioColumnHelper.accessor((row) => row["description"], {
    header: "Description",
    id: "description",
    filterVariant: "text",
  }),
  scenarioColumnHelper.accessor((row) => row["owners"].join(","), {
    header: "Owners",
    id: "owners",
    filterVariant: "text",
  }),
  scenarioColumnHelper.accessor(
    (row) => new Date(row["updatedAt"]).toLocaleString(),
    {
      header: "Updated",
      id: "updatedAt",
      filterVariant: "datetime-range",
    }
  ),
];

// Correctly typing addOrgScenarios mutation
type AddOrgScenariosInput = {
  orgID: string;
  scenarioIDs: string[];
  owners: string[];
};

const ScenarioStore = ({ openStoreDialog, setOpenStoreDialog }) => {
  const theme = useTheme();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const { removeOrgScenarios, addOrgScenarios } = useServerStateMutations();
  const defaultScenariotMRTOptions = getDefaultMRTOptions<Scenario>(theme);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

  const {
    data: scenarioDataInfinite,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError: isErrorInfinite,
    isLoading: isLoadingInfinite,
    isFetching: isFetchingInfinite,
    isPending,
    error: scenarioDataError,
  } = useScenarioStoreInfinite({ limit: 100 });

  const scenarioData = useMemo(() => {
    let items = scenarioDataInfinite?.pages.flatMap((page) => page.items) ?? [];

    // Combine the arrays
    let combinedData = [
      ...orgScenerios.map((orgScenario) => orgScenario.scenario),
      ...items,
    ];

    // Deduplicate by id
    let uniqueData = Array.from(
      new Map(combinedData.map((item) => [item.id, item])).values()
    );

    logger.info("scenarioData", uniqueData);
    return uniqueData;
  }, [scenarioDataInfinite, orgScenerios]);

  // Correctly typing removeOrgScenarios mutation
  const removeOrgScenariosWithTypes = useCallback(
    (orgScenarios: OrgScenario[]) => {
      return removeOrgScenarios.mutateAsync(orgScenarios as any); // Cast to 'any' for mutateAsync to accept the argument
    },
    [removeOrgScenarios]
  );

  const addOrgScenariosWithTypes = useCallback(
    (input: AddOrgScenariosInput) => {
      return addOrgScenarios.mutateAsync(input as any); // Cast to 'any' for mutateAsync to accept the argument
    },
    [addOrgScenarios]
  );

  // on mount, fetch all pages until we have all the data
  useEffect(() => {
    if (isPending || isLoadingInfinite) return;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [
    isPending,
    isLoadingInfinite,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  ]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetchingInfinite &&
          hasNextPage &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetchingInfinite, hasNextPage, isFetchingNextPage]
  );

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const scenariosTable = useMaterialReactTable({
    ...defaultScenariotMRTOptions,
    //@ts-ignore
    columns: scenarioColumns,
    data: scenarioData,
    getRowId: (row) => `${row.id}`,
    enableEditing: false,
    enableRowPinning: true,
    rowPinningDisplayMode: "select-top",
    layoutMode: "grid",
    keepPinnedRows: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    muiTableContainerProps: {
      ...defaultScenariotMRTOptions.muiTableContainerProps,
      ref: tableContainerRef, //get access to the table container element
      sx: {
        flex: 1,
        overflow: "auto", // Table scrolls internally for rows
        maxHeight: "calc(70vh - 100px)", // Adjust based on your content size
      },
    },
    muiToolbarAlertBannerProps: isErrorInfinite
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    initialState: {
      ...defaultScenariotMRTOptions.initialState,
      isLoading: isLoadingInfinite,
      showAlertBanner: isErrorInfinite,
      showProgressBars: isFetchingInfinite,
      columnVisibility: { id: false },
      showColumnFilters: true,
      density: "compact",
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isLoadingInfinite,
      showProgressBars: isFetchingInfinite,
    },
    rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: { overscan: 4 },
    enableBottomToolbar: true,
  });

  useEffect(() => {
    if (isPending || isLoadingInfinite) return;
    if (openStoreDialog && scenarioData?.length) {
      scenariosTable.setRowSelection(
        orgScenerios.reduce((acc, orgScenario) => {
          acc[orgScenario.scenarioID] = true;
          return acc;
        }, {})
      );
      scenariosTable.setRowPinning({
        top: orgScenerios.map((orgScenario) => orgScenario.scenarioID),
      });
    }
  }, [
    openStoreDialog,
    scenarioData,
    orgScenerios,
    isPending,
    isLoadingInfinite,
    scenariosTable,
  ]);

  const handleSubmit = useCallback(
    async function handleSubmit() {
      // compare the scenario IDs (keys) of the selected rows with the scenario IDs of the orgScenarios, returning a list of scenario IDs to remove
      const selectedRows = scenariosTable.getSelectedRowModel();
      logger.info("selectedRows", selectedRows);
      const orgScenariosToRemove = orgScenerios.filter(
        (orgScenario) => !selectedRows.rowsById[orgScenario.scenarioID]
      );
      logger.info("orgScenariosToRemove", orgScenariosToRemove);

      // compare the scenario IDs (keys) of the selected rows with the scenario IDs of the orgScenarios, returning a list of scenario IDs to add
      const scenarioIDsToAdd = Object.keys(selectedRows.rowsById).filter(
        (scenarioID) =>
          selectedRows.rowsById[scenarioID] &&
          !orgScenerios.some(
            (orgScenario) => orgScenario.scenarioID === scenarioID
          )
      );
      logger.info("scenarioIDsToAdd", scenarioIDsToAdd);

      removeOrgScenariosWithTypes(orgScenariosToRemove);
      addOrgScenariosWithTypes({
        orgID: demoConfig?.id!,
        scenarioIDs: scenarioIDsToAdd,
        owners: demoConfig?.owners!,
      });
      setOpenStoreDialog(false);
      scenariosTable.setRowSelection({});
    },
    [
      addOrgScenariosWithTypes,
      removeOrgScenariosWithTypes,
      setOpenStoreDialog,
      orgScenerios,
      demoConfig?.id,
      demoConfig?.owners,
      scenariosTable,
    ]
  );

  if (scenarioDataError)
    return "An error has occurred: " + scenarioDataError.message;

  return (
    <Dialog
      open={openStoreDialog}
      onClose={() => setOpenStoreDialog(false)}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>DemoSim Scenario Store</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          // maxHeight: "80vh",
          // overflow: "hidden",
        }}
      >
        <DialogContentText>
          DemoSim scenarios available to you.
        </DialogContentText>
        {isPending ? (
          <span>Loading...</span>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              // height: "100%",
            }}
          >
            <MaterialReactTable table={scenariosTable} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenStoreDialog(false);
            scenariosTable.setRowSelection({});
          }}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="success">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioStore;
