import { API, graphqlOperation, Logger } from "aws-amplify";
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queriesCustom from "graphql/queries-custom";
import * as mutationsCustom from "graphql/mutations-custom";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import {
  initialOrgState,
  initialBpOrgInfo,
} from "templates/initialStateTemplates";

import Bottleneck from "bottleneck";

const R = require("ramda");

const logger = new Logger("GraphQL Functions", "INFO");

const roughSizeOfObject = function roughSizeOfObject(object) {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
};

const calcMaxElementsForDdb = function calcMaxElementsForDdb(offset = 0, arr) {
  let elSizes = arr.map((el) => roughSizeOfObject(el));
  let maxElements = 0;
  elSizes.reduce((totalsize, elSize) => {
    if (totalsize + offset + elSize < 400 * 1024) maxElements++;
    return totalsize + elSize;
  }, 0);
  return maxElements;
};

// export a function that calls the GraphQL API for the mutationsCustom.postUtil query
export const postUtil = async function postUtil({
  access_token,
  region,
  params,
  payload,
}) {
  logger.info(
    `graphqlFunction postUtil: ${access_token}, ${region}, ${JSON.stringify(
      params
    )}, ${JSON.stringify(payload)}`
  );
  return API.graphql(
    graphqlOperation(mutationsCustom.postUtil, {
      access_token,
      region,
      params: JSON.stringify(params),
      payload: JSON.stringify(payload),
    })
  )
    .then((res) => {
      let result = JSON.parse(res.data.postUtil);
      return result?.statusCode === 200 ? result?.body : [];
    })
    .catch((error) => {
      logger.error(`postUtil error:`, error);
      throw error;
    });
};

// export a function that calls the GraphQL API for the mutationsCustom.queryOpenAI query
export const queryOpenAI = async function queryOpenAI(config) {
  logger.info(`graphqlFunction queryOpenAI: ${JSON.stringify(config)}`);
  return API.graphql(
    graphqlOperation(mutationsCustom.queryOpenAI, {
      config: JSON.stringify(config),
    })
  )
    .then((res) => {
      let result = JSON.parse(res.data.queryOpenAI);
      return result?.statusCode === 200 ? result?.body : [];
    })
    .catch((error) => {
      logger.error(`queryOpenAI error:`, error);
      throw error;
    });
};

export const fetchScenarioOrgs = async function fetchScenarioOrgs(scenarioID) {
  return API.graphql(
    graphqlOperation(queriesCustom.getScenarioOrgs, { id: scenarioID })
  )
    .then((res) => res.data.getScenario)
    .catch((error) => {
      logger.error("fetchScenarioOrgs error:", error);
      throw error;
    });
};

const getOrgScenario = async function getOrgScenario(orgScenarioID) {
  return API.graphql(
    graphqlOperation(queriesCustom.getOrgScenario, { id: orgScenarioID })
  )
    .then((result) => result.data.getOrgScenario)
    .catch((error) => {
      logger.warn(`getOrgScenario error:`, error);
      throw error;
    });
};

// export const listOrgScenarioIDs = async function listOrgScenarioIDs(demoConfigId) {
//   let queryOrgScenarioIDs = async function queryOrgScenarioIDs({
//     nextToken,
//     items,
//   }) {
//     return API.graphql(
//       graphqlOperation(queriesCustom.listOrgScenarioIDs, {
//         filter: {
//           orgID: { eq: demoConfigId },
//         },
//         limit: 100,
//         nextToken,
//       })
//     )
//       .then((result) => {
//         if (result.data.listOrgScenarios.nextToken !== null)
//           return queryOrgScenarioIDs({
//             nextToken: result.data.listOrgScenarios.nextToken,
//             items: [...items, ...result.data.listOrgScenarios.items],
//           });
//         return [...items, ...result.data.listOrgScenarios.items];
//       })
//       .catch((error) => {
//         logger.error("queryOrgScenarioIDs", error);
//         throw error;
//       });
//   };

//   let nextToken = null;
//   let items = [];
//   let orgScenarioIDs = await queryOrgScenarioIDs({ nextToken, items }).then(
//     (orgScenarioIDs) => {
//       logger.info("orgScenarioIDs:", orgScenarioIDs);
//       return orgScenarioIDs.map((item) => item.id);
//     }
//   );
//   return orgScenarioIDs;
// };

export const listOrgScenarioIDs = async function listOrgScenarioIDs(
  demoConfigId
) {
  let queryOrgScenarioIDs = async function queryOrgScenarioIDs({
    nextToken,
    items,
  }) {
    return API.graphql(
      graphqlOperation(queriesCustom.listOrgScenarioIDs, {
        id: demoConfigId,
      })
    )
      .then((result) => {
        if (result.data.getOrg.scenarios.nextToken !== null)
          return queryOrgScenarioIDs({
            nextToken: result.data.getOrg.scenarios.nextToken,
            items: [...items, ...result.data.getOrg.scenarios.items],
          });
        return [...items, ...result.data.getOrg.scenarios.items];
      })
      .catch((error) => {
        logger.error("queryOrgScenarioIDs", error);
        throw error;
      });
  };

  let nextToken = null;
  let items = [];
  let orgScenarioIDs = await queryOrgScenarioIDs({ nextToken, items }).then(
    (orgScenarioIDs) => {
      logger.info("orgScenarioIDs:", orgScenarioIDs);
      return orgScenarioIDs.map((item) => item.id);
    }
  );
  return orgScenarioIDs;
};

export const listOrgScenarios = async function listOrgScenarios(demoConfigId) {
  logger.info("looking for scenarios for ", demoConfigId);
  let orgScenarioIDs = await listOrgScenarioIDs(demoConfigId);
  const getAllOrgScenarios = orgScenarioIDs.map(async (orgScenarioID) =>
    getOrgScenario(orgScenarioID).catch(async (e) => {
      logger.warn(`Error retrieving orgScenario ${orgScenarioID}`, e);
      return null;
    })
  );
  let orgScenarios = await Promise.all(getAllOrgScenarios);
  return orgScenarios.filter((os) => os?.id);
};

export const listEventPages = async function listEventPages(scenarioID) {
  let queryEventPages = async function queryEventPages({ nextToken, items }) {
    return API.graphql(
      graphqlOperation(queriesCustom.listEventPages, {
        filter: {
          scenarioEventPagesId: { eq: scenarioID },
        },
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listEventPages.nextToken !== null)
          return queryEventPages({
            nextToken: result.data.listEventPages.nextToken,
            items: [...items, ...result.data.listEventPages.items],
          });
        return [...items, ...result.data.listEventPages.items];
      })
      .catch((error) => {
        logger.error("queryEventPages", error);
        throw error;
      });
  };

  let nextToken = null;
  let items = [];
  let eventPages = await queryEventPages({ nextToken, items });
  return eventPages;
};

export const listChangePages = async function listChangePages(scenarioID) {
  let queryChangePages = async function queryChangePages({ nextToken, items }) {
    return API.graphql(
      graphqlOperation(queriesCustom.listChangePages, {
        filter: {
          scenarioChangePagesId: { eq: scenarioID },
        },
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listChangePages.nextToken !== null)
          return queryChangePages({
            nextToken: result.data.listChangePages.nextToken,
            items: [...items, ...result.data.listChangePages.items],
          });
        return [...items, ...result.data.listChangePages.items];
      })
      .catch((error) => {
        logger.error("queryChangePages", error);
        throw error;
      });
  };

  let nextToken = null;
  let items = [];
  let changePages = await queryChangePages({ nextToken, items });
  return changePages;
};

export const getScenario = async (scenarioID) => {
  let scenarioData = await API.graphql(
    graphqlOperation(queriesCustom.getScenario, { id: scenarioID })
  )
    .then((result) => result.data.getScenario)
    .catch((error) => {
      logger.error(`getScenario error:`, error);
      throw error;
    });
  if (scenarioData.eventPages.nextToken !== null)
    scenarioData.eventPages.items = await listEventPages(scenarioID);
  if (scenarioData.changePages.nextToken !== null)
    scenarioData.changePages.items = await listChangePages(scenarioID);

  return scenarioData;
};

export const updateScenario = (newScenarioData) =>
  API.graphql(
    graphqlOperation(mutationsCustom.updateScenario, {
      input: newScenarioData,
    })
  )
    .then((result) => result.data.updateScenario)
    .catch((error) => {
      logger.error(`updateScenario error:`, error);
      throw error;
    });

const createScenarioEventPage = async function createScenarioEventPage(
  pageNum,
  scenario,
  events
) {
  try {
    let newScenarioEventsPage = await API.graphql({
      query: mutationsCustom.createEventPage,
      variables: {
        input: {
          page: pageNum,
          groupsCanEdit: scenario.groupsCanEdit,
          groupsCanRead: scenario.groupsCanRead,
          owners: scenario.owners,
          events,
          scenarioEventPagesId: scenario.id,
        },
      },
    }).then((result) => result.data.createEventPage);

    logger.info(
      `createScenarioEventPage result for scenairo ${scenario.name} (${scenario.id}):`,
      newScenarioEventsPage
    );
    return newScenarioEventsPage;
  } catch (error) {
    logger.error(
      `createScenarioEventPage error for scenairo ${scenario.name} (${scenario.id}):`,
      error
    );
    throw error;
  }
};

const createScenarioChangePage = async function createScenarioChangePage(
  pageNum,
  scenario,
  changes
) {
  try {
    let newScenarioChangesPage = await API.graphql({
      query: mutationsCustom.createChangePage,
      variables: {
        input: {
          page: pageNum,
          groupsCanEdit: scenario.groupsCanEdit,
          groupsCanRead: scenario.groupsCanRead,
          owners: scenario.owners,
          changes,
          scenarioChangePagesId: scenario.id,
        },
      },
    }).then((result) => result.data.createChangePage);

    logger.info("createScenarioChangePage result:", newScenarioChangesPage);
    return newScenarioChangesPage;
  } catch (error) {
    logger.error(`createScenarioChangePage error:`, error);
    throw error;
  }
};

const parseRecordsToPages = function parseRecordsToPages(origRecords) {
  let records = [...origRecords];
  let pages = [];
  if (calcMaxElementsForDdb(0, records) < records.length) {
    let recordsCount = records.length;
    while (records.length > 0) {
      let maxRows = calcMaxElementsForDdb(0, records);
      pages.push(records.splice(0, maxRows));
    }
    // move first page of pages to records
    records = pages.shift();
    logger.info(
      `parseRecordsToPages: ${recordsCount} records split to ${pages.length} pages`
    );
  }
  return [records, pages];
};

const deleteChangePage = async function deleteChangePage(changePageID) {
  return API.graphql(
    graphqlOperation(mutationsCustom.deleteChangePage, {
      input: { id: changePageID },
    })
  ).catch((error) => {
    logger.error(`deleteChangePage error:`, error);
    throw error;
  });
};

const deleteAllScenarioChangePages =
  async function deleteAllScenarioChangePages(scenarioID) {
    let changePages = await listChangePages(scenarioID);
    logger.info("changePages:", changePages);
    if (changePages.length > 0) {
      logger.info("deleting change pages", changePages);
      let deletePages = changePages.map((changePage) => {
        logger.info("deleting changePage id", changePage.id);
        return deleteChangePage(changePage.id);
      });
      await Promise.all(deletePages);
    }

    // let scenarioMeta = await fetchScenarioMeta(scenarioID);
    // logger.info("scenarioMeta:", scenarioMeta);
    // if (scenarioMeta?.changePages?.items.length > 0) {
    //   logger.info("deleting change pages", scenarioMeta.changePages.items);
    //   let deletePages = scenarioMeta.changePages.items.map((changePage) => {
    //     logger.info("deleting changePage id", changePage.id);
    //     return deleteChangePage(changePage.id);
    //   });
    //   await Promise.all(deletePages);
    // }
  };

const deleteEventPage = async function deleteEventPage(eventPageID) {
  return API.graphql(
    graphqlOperation(mutationsCustom.deleteEventPage, {
      input: { id: eventPageID },
    })
  ).catch((error) => {
    logger.error(`deleteEventPage error:`, error);
    throw error;
  });
};

const deleteAllScenarioEventPages = async function deleteAllScenarioEventPages(
  scenarioID
) {
  let eventPages = await listEventPages(scenarioID);
  logger.info("eventPages:", eventPages);
  if (eventPages.length > 0) {
    logger.info("deleting event pages", eventPages);
    let deletePages = eventPages.map((eventPage) => {
      logger.info("deleting eventPage id", eventPage.id);
      return deleteEventPage(eventPage.id);
    });
    await Promise.all(deletePages);
  }
  // let scenarioMeta = await fetchScenarioMeta(scenarioID);
  // logger.info("scenarioMeta:", scenarioMeta);
  // if (scenarioMeta?.eventPages?.items.length > 0) {
  //   logger.info("deleting pages", scenarioMeta.eventPages.items);
  //   let deletePages = scenarioMeta.eventPages.items.map((eventPage) => {
  //     logger.info("deleting eventpage id", eventPage.id);
  //     return deleteEventPage(eventPage.id);
  //   });
  //   await Promise.all(deletePages);
  // }
};

export const mutateScenarioRecord = async function mutateScenarioRecord(
  updatedScenarioData
) {
  try {
    let newData = R.clone(updatedScenarioData);
    logger.info("mutateScenarioRecord: newData", newData);

    // handle changes
    let [changeRecords, changePages] = parseRecordsToPages(newData.changes);
    logger.info("mutateScenarioRecord: changeRecords", changeRecords);
    logger.info("mutateScenarioRecord: changePages", changePages);
    newData.changes = changeRecords;
    // should be no changePages from the editor, this is a fallback
    delete newData.changePages;

    // handle events
    let [eventRecords, eventPages] = parseRecordsToPages(newData.events);
    logger.info("mutateScenarioRecord: eventRecords", eventRecords);
    logger.info("mutateScenarioRecord: eventPages", eventPages);
    newData.events = eventRecords;
    // should be no eventPages from the editor, this is a fallback
    delete newData.eventPages;

    // this first deletes all pages in the database, then updates the scenario with the new data from the editor
    return deleteAllScenarioChangePages(newData.id)
      .then(() => deleteAllScenarioEventPages(newData.id))
      .then(() => updateScenario(newData))
      .then((updatedScenario) => {
        logger.info(
          `mutateScenarioRecord: mutated ${updatedScenario.name} (prior to creating event/change pages):`,
          updatedScenario
        );
        let createPages = [];
        if (eventPages.length > 0) {
          let createEventPages = eventPages.map((eventPage, idx) =>
            createScenarioEventPage(idx + 1, updatedScenario, eventPage)
          );
          createPages.push(...createEventPages);
        }

        if (changePages.length > 0) {
          let createChangePages = changePages.map((changePage, idx) =>
            createScenarioChangePage(idx + 1, updatedScenario, changePage)
          );
          createPages.push(...createChangePages);
        }
        return Promise.all(createPages);
      })
      .then(() => getScenario(updatedScenarioData.id));
  } catch (error) {
    logger.error("updateScenarioData: Error updating scenario:", error);
    throw error;
  }
};

export const deleteScenarioRecord = async function deleteScenarioRecord(
  scenarioID
) {
  await deleteAllScenarioEventPages(scenarioID);
  await deleteAllScenarioChangePages(scenarioID);
  return API.graphql(
    graphqlOperation(mutationsCustom.deleteScenario, {
      input: { id: scenarioID },
    })
  )
    .then((result) => result.data.deleteScenario)
    .catch((err) => {
      logger.error("deleteScenarioRecord error:", err);
      throw err;
    });
};

export const createScenarioRecord = async function createScenarioRecord(
  scenario
) {
  try {
    let origScenario = { ...scenario };
    //TODO
    // let currentUser = await Auth.currentAuthenticatedUser();
    // origScenario.owners = [
    //   ...new Set([...demoConfig.owners, currentUser.username]),
    // ];

    // handle changes
    let [changeRecords, changePages] = parseRecordsToPages(
      origScenario.changes
    );
    logger.info("createScenarioRecord: changeRecords", changeRecords);
    logger.info("createScenarioRecord: changePages", changePages);
    origScenario.changes = changeRecords;
    delete origScenario.changePages;

    // handle events
    let [eventRecords, eventPages] = parseRecordsToPages(origScenario.events);
    logger.info("createScenarioRecord: eventRecords", eventRecords);
    logger.info("createScenarioRecord: eventPages", eventPages);
    origScenario.events = eventRecords;
    delete origScenario.eventPages;

    // Create scenario
    logger.info(
      "createScenario: creating new scenario:",
      origScenario,
      eventPages,
      changePages
    );

    let newScenario = await API.graphql({
      query: mutationsCustom.createScenario,
      variables: { input: origScenario },
    }).then((result) => result.data.createScenario);
    logger.info(
      "createScenario: created newScenario (prior to creating event pages):",
      newScenario
    );

    if (eventPages.length > 0) {
      let createEventPages = eventPages.map((eventPage, idx) =>
        createScenarioEventPage(idx + 1, newScenario, eventPage)
      );
      await Promise.all(createEventPages);
    }

    if (changePages.length > 0) {
      let createChangePages = changePages.map((changePage, idx) =>
        createScenarioChangePage(idx + 1, newScenario, changePage)
      );
      await Promise.all(createChangePages);
    }

    let finalScenarioData = await getScenario(newScenario.id);
    logger.info("createScenarioRecord: finalScenarioData", finalScenarioData);
    return finalScenarioData;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const getOrgRecord = (demoConfigId) =>
  API.graphql(graphqlOperation(queriesCustom.getOrg, { id: demoConfigId }))
    .then((res) => (res.data.getOrg ? res.data.getOrg : initialOrgState))
    .catch(async (error) => {
      logger.error(`getOrgRecord error:`, error);
      throw error;
    });

export const deleteOrgRecord = (id) =>
  API.graphql(graphqlOperation(mutationsCustom.deleteOrg, { input: { id } }))
    .then((res) => res.data.deleteOrg)
    .catch((error) => {
      logger.error(`deleteOrgRecord error:`, error);
      throw error;
    });

export const getBpIntegrations = ({ region, access_token }) => {
  const regionalQuery =
    region === "EU" ? "listBpIntegrationsEU" : "listBpIntegrations";
  return API.graphql(
    graphqlOperation(queriesCustom[regionalQuery], { query: { access_token } })
  )
    .then((res) =>
      res?.data[regionalQuery]?.item ? res?.data[regionalQuery]?.item : []
    )
    .catch((error) => {
      logger.error(`getBpIntegrations error:`, error);
      return [];
    });
};

export const getBpOrgInfo = ({ region, access_token }) => {
  const regionalQuery = region === "EU" ? "getBpOrgInfoEU" : "getBpOrgInfo";
  return API.graphql(
    graphqlOperation(queriesCustom[regionalQuery], { query: { access_token } })
  )
    .then((res) =>
      res?.data[regionalQuery] ? res?.data[regionalQuery] : initialBpOrgInfo
    )
    .catch((error) => {
      logger.error(`getBpOrgInfo error:`, error);
      return initialBpOrgInfo;
    });
};

export const getBpEnvironments = ({ region, access_token }) => {
  const regionalQuery =
    region === "EU" ? "getBpEnvironmentsEU" : "getBpEnvironments";
  return API.graphql(
    graphqlOperation(queriesCustom[regionalQuery], { query: { access_token } })
  )

    .then((res) =>
      res?.data[regionalQuery] ? JSON.parse(res?.data[regionalQuery]) : []
    )
    .catch((error) => {
      logger.error(`getBpEnvironments error:`, error);
      throw error;
    });
};

export const createOrgRecord = (orgData) =>
  API.graphql(graphqlOperation(mutationsCustom.createOrg, { input: orgData }))
    .then((res) => res.data.createOrg)
    .catch((error) => {
      logger.error(`createOrgRecord error:`, error);
      throw error;
    });

export const addOrgScenario = (orgID, scenarioID, owners) => {
  logger.info("addOrgScenario", orgID, scenarioID, owners);
  return API.graphql(
    graphqlOperation(mutationsCustom.createOrgScenario, {
      input: {
        orgID,
        scenarioID,
        owners,
      },
    })
  )
    .then((res) => res.data.createOrgScenario)
    .catch((error) => {
      logger.error(`addOrgScenario error:`, error);
      throw error;
    });
};

export const mutateOrgRecord = async (orgData) =>
  API.graphql(graphqlOperation(mutationsCustom.updateOrg, { input: orgData }))
    .then((res) => res.data.updateOrg)
    .catch((error) => {
      logger.error(`mutateOrgRecord error:`, error);
      throw error;
    });

export const deleteOrgScenario = async (orgScenarioID) => {
  // find all crontabs for this scenario and delete them
  logger.info("deleteOrgScenario", orgScenarioID);
  let crontabs = await listScenarioCronTabs(orgScenarioID);

  logger.info("deleting crontabs:", crontabs);
  if (crontabs.length > 0) {
    let deleteCrontabs = crontabs.map((crontab) => deleteCronTab(crontab.id));
    Promise.all(deleteCrontabs);
  }

  API.graphql(
    graphqlOperation(mutationsCustom.deleteOrgScenario, {
      input: { id: orgScenarioID },
    })
  ).catch((error) => {
    logger.error(`deleteOrgScenario error:`, error);
    // throw error;
  });
};

const getBulkOrgRecords = (username, nextToken) => {
  return API.graphql(
    graphqlOperation(queriesCustom.listOrgs, {
      filter: { owners: { contains: username } },
      limit: 100,
      nextToken,
    })
  );
};

export const listDemoConfigs = async function listDemoConfigs(data) {
  let nextToken = undefined;
  let items = [];
  let username = data.hasOwnProperty("queryKey") ? data.queryKey[1] : data;
  let paginatedListDemoConfigs = async function paginatedListDemoConfigs({
    username,
    nextToken,
    items,
  }) {
    return getBulkOrgRecords(username, nextToken)
      .then((result) => {
        if (result.data.listOrgs.nextToken !== null)
          return paginatedListDemoConfigs({
            username,
            nextToken: result.data.listOrgs.nextToken,
            items: [...items, ...result.data.listOrgs.items],
          });
        return [...items, ...result.data.listOrgs.items];
      })
      .catch((error) => {
        logger.error(`listDemoConfigs error:`, error);
        throw error;
      });
  };
  // await
  return paginatedListDemoConfigs({ username, nextToken, items }).then(
    (orgItems) => {
      logger.info(
        "listDemoConfigs - Demo Configs (paginated) updated:",
        orgItems
      );
      return orgItems;
    }
  );
};

export const fetchScenarioRecord = async function fetchScenarioRecord(
  scenarioID
) {
  let scenarioData = await getScenario(scenarioID);
  if (scenarioData?.eventPages?.items.length > 0) {
    // merge eventPages into events then empty out eventPages
    scenarioData.events = [
      ...scenarioData.events,
      ...scenarioData.eventPages.items.flatMap((eventPage) =>
        eventPage.events.splice(0)
      ),
    ];
    delete scenarioData.eventPages;
  }
  if (scenarioData?.changePages?.items.length > 0) {
    // merge changePages into changes then empty out changePages
    scenarioData.changes = [
      ...scenarioData.changes,
      ...scenarioData.changePages.items.flatMap((changePage) =>
        changePage.changes.splice(0)
      ),
    ];
    delete scenarioData.changePages;
  }
  return scenarioData;
};

export const listScenarios = async function listScenarios() {
  let nextToken = null;
  let items = [];
  let recursiveListAllScenarios = async function recursiveListAllScenarios({
    nextToken,
    items,
  }) {
    return API.graphql(
      graphqlOperation(queriesCustom.listScenarioStore, {
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listScenarios.nextToken !== null)
          return recursiveListAllScenarios({
            nextToken: result.data.listScenarios.nextToken,
            items: [...items, ...result.data.listScenarios.items],
          });
        return [...items, ...result.data.listScenarios.items];
      })
      .catch((error) => {
        logger.error("recursiveListAllScenarios", error);
        throw error;
      });
  };
  return recursiveListAllScenarios({ nextToken, items }).then((scenarios) => {
    scenarios.sort((a, b) =>
      a?.name.toUpperCase() < b?.name.toUpperCase()
        ? -1
        : a?.name.toUpperCase() > b?.name.toUpperCase()
          ? 1
          : 0
    );
    logger.info("listScenarios - Scenarios (paginated) updated:", scenarios);
    return scenarios;
  });

  // let scenarios = await recursiveListAllScenarios({ nextToken, items });
  // scenarios.sort((a, b) =>
  //   a?.name.toUpperCase() < b?.name.toUpperCase()
  //     ? -1
  //     : a?.name.toUpperCase() > b?.name.toUpperCase()
  //       ? 1
  //       : 0
  // );
  // return scenarios;
};

export const listScenarioStore = async function listScenarioStore(
  limit = 100,
  nextToken
) {
  const variables = { limit };
  if (nextToken) variables.nextToken = nextToken;
  return API.graphql(
    graphqlOperation(queriesCustom.listScenarioStore, variables)
  ).then((result) => result.data.listScenarios);
};

export const getBpIncidents = async function getBpIncidents({
  access_token,
  region,
  envId,
  filter,
}) {
  logger.info(
    `graphqlFunction getBpIncidents: ${access_token}, ${region}, ${envId}, ${JSON.stringify(
      filter
    )}`
  );
  return API.graphql(
    graphqlOperation(queriesCustom.getBpIncidents, {
      access_token,
      region,
      envId,
      filter: JSON.stringify(filter),
    })
  )
    .then((res) => {
      let result = JSON.parse(res.data.getBpIncidents);
      logger.info("getBpIncidents result:", result);
      return result?.statusCode === 200 ? result?.body : [];
    })
    .catch((error) => {
      logger.error(`getBpIncidents error:`, error);
      throw error;
    });
};

export const getBpIncidentEvents = async function getBpIncidentEvents({
  access_token,
  region,
  incidentIDs,
}) {
  const throttle_rate = 3;
  // logger.info(
  //   `graphqlFunction getBpIncidentEvents: ${access_token}, ${region}, ${incidentIDs}`
  // );
  // Setup throttling handler
  const limiter = new Bottleneck({
    minTime: 1000 / throttle_rate, // spread out posts across one second
    maxConcurrent: 1, // submit 1 at a time
  });
  // Tool-specific event handlers (customize text and handlers)
  limiter.on("failed", async (error, jobInfo) => {
    if (error?.response?.status === 429) {
      logger.error(
        `bpGetIncidentEvents Throttle error retrieving incident events ${
          jobInfo.options.id
        } at ${Date.now().toLocaleString()}`
      );
      return throttle_rate * 100; // wait # ms before retrying
    }
    logger.error(
      `bpGetIncidentEvents Error retrieving incident events ${
        jobInfo.options.id
      } at ${Date.now().toLocaleString()}; pausing ${throttle_rate / 2} seconds.`,
      error
    );
    return (throttle_rate / 2) * 1000; // wait x ms before retrying
  });
  limiter.on("executing", async (info) => {
    logger.verbose(
      `bpGetIncidentEvents Retrieving incident events ${info.options.id} at ${Date.now().toLocaleString()}`
    );
  });
  // limiter.on("empty", function () {
  // requires matching console.time() event prior to job scheduling
  // console.timeEnd(`Time to retrieve incident events`);
  // });
  // Generic tracing events, verbose log level
  limiter.on("retry", (error, jobInfo) =>
    logger.info(
      `Retrying job ${jobInfo.options.id} at ${Date.now().toLocaleString()}`
    )
  );
  limiter.on("depleted", function (empty) {
    logger.info(
      `bpGetIncidentEvents Reservoir depleted at ${Date.now().toLocaleString()}, empty = ${empty}`
    );
  });
  limiter.on("scheduled", async (info) => {
    logger.info(
      `bpGetIncidentEvents Scheduled job ${info.options.id} at ${Date.now().toLocaleString()}`
    );
  });
  limiter.on("bpGetIncidentEvents done", function (info) {
    logger.info(
      `bpGetIncidentEvents Job ${info.options.id} completed at ${Date.now().toLocaleString()}`
    );
  });

  const getAllEntities = incidentIDs.map((incidentId) =>
    limiter.schedule({ id: incidentId, expiration: 29000 }, () =>
      // 29 seconds because of lambda timeout
      API.graphql(
        graphqlOperation(queriesCustom.getBpIncidentEvents, {
          access_token,
          region,
          incidentId,
        })
      )
        .then((res) => JSON.parse(res.data.getBpIncidentEvents)?.body)
        .catch((error) => {
          logger.error(`getBpIncidentEvents error:`, error);
          throw error;
        })
    )
  );
  let allEntities = await Promise.all(getAllEntities);
  return allEntities.flat();
};

export const postChanges = async function postChanges({
  access_token,
  region,
  changes,
}) {
  logger.info(
    `graphqlFunction postChanges: ${access_token}, ${region}, ${JSON.stringify(
      changes
    )}`
  );
  return API.graphql(
    graphqlOperation(mutationsCustom.postChanges, {
      access_token,
      region,
      changes: JSON.stringify(changes),
    })
  )
    .then((res) => {
      let result = JSON.parse(res.data.postChanges);
      if (result.length > 1) {
        return result;
      }
      if (result.length === 1 && result[0].status === "rejected")
        throw result[0].reason;
      return result;
    })
    .catch((error) => {
      logger.error(`graphqlFunction postChanges error:`, error);
      throw error;
    });
};

export const listResources = async function listResources() {
  let nextToken = null;
  let items = [];
  let queryResources = async function queryResources({ nextToken, items }) {
    return API.graphql(
      graphqlOperation(queries.listResources, {
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listResources.nextToken !== null)
          return queryResources({
            nextToken: result.data.listResources.nextToken,
            items: [...items, ...result.data.listResources.items],
          });
        return [...items, ...result.data.listResources.items];
      })
      .catch((error) => {
        logger.error("queryResources", error);
        throw error;
      });
  };
  let resources = await queryResources({ nextToken, items });
  resources.sort((a, b) =>
    a?.name.toUpperCase() < b?.name.toUpperCase()
      ? -1
      : a?.name.toUpperCase() > b?.name.toUpperCase()
      ? 1
      : 0
  );
  return resources;
};

export const getResource = async function getResource(resourceID) {
  try {
    let resource = await API.graphql({
      query: queries.getResource,
      variables: { id: resourceID },
    }).then((result) => result.data.getResource);
    logger.info("getResource result:", resource);
    return resource;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
export const createResource = async function createResource(resource) {
  try {
    let newResource = await API.graphql({
      query: mutations.createResource,
      variables: { input: resource },
    }).then((result) => result.data.createResource);

    logger.info("createResource result:", newResource);
    return newResource;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const updateResource = async function updateResource(resource) {
  try {
    let updatedResource = await API.graphql({
      query: mutations.updateResource,
      variables: { input: resource },
    }).then((result) => result.data.updateResource);

    logger.info("updateResource result:", updatedResource);
    return updatedResource;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const deleteResource = async function deleteResource(resourceID) {
  try {
    let deletedResource = await API.graphql({
      query: mutations.deleteResource,
      variables: { input: { id: resourceID } },
    }).then((result) => result.data.deleteResource);

    logger.info("deleteResource result:", deletedResource);
    return deletedResource;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const listSchedules = async function listSchedules() {
  let nextToken = null;
  let items = [];
  let querySchedules = async function querySchedules({ nextToken, items }) {
    return API.graphql(
      graphqlOperation(queriesCustom.listSchedules, {
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listSchedules.nextToken !== null)
          return querySchedules({
            nextToken: result.data.listSchedules.nextToken,
            items: [...items, ...result.data.listSchedules.items],
          });
        return [...items, ...result.data.listSchedules.items];
      })
      .catch((error) => {
        logger.error("querySchedules", error);
        throw error;
      });
  };
  return querySchedules({ nextToken, items });
};

export const getSchedule = async function getSchedule(scheduleID) {
  try {
    let schedule = await API.graphql({
      query: queries.getSchedule,
      variables: { event_id: scheduleID },
    }).then((result) => result.data.getSchedule);

    logger.info("getSchedule result:", schedule);
    return schedule;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const createSchedule = async function createSchedule(schedule) {
  try {
    let newSchedule = await API.graphql({
      query: mutations.createSchedule,
      variables: { input: schedule },
    }).then((result) => result.data.createSchedule);

    logger.info("createSchedule result:", newSchedule);
    return newSchedule;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const updateSchedule = async function updateSchedule(schedule) {
  try {
    let updatedSchedule = await API.graphql({
      query: mutations.updateSchedule,
      variables: { input: schedule },
    }).then((result) => result.data.updateSchedule);

    logger.info("updateSchedule result:", updatedSchedule);
    return updatedSchedule;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const deleteSchedule = async function deleteSchedule(scheduleID) {
  try {
    let deletedSchedule = await API.graphql({
      query: mutations.deleteSchedule,
      variables: { input: { event_id: scheduleID } },
    }).then((result) => result.data.deleteSchedule);

    logger.info("deleteSchedule result:", deletedSchedule);
    return deletedSchedule;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

// cronTabs
export const listScenarioCronTabs = async function listScenarioCronTabs(
  orgScenarioID
) {
  let nextToken = null;
  let items = [];
  let queryCronTabs = async function queryCronTabs({ nextToken, items }) {
    return API.graphql(
      graphqlOperation(queries.listCronTabs, {
        filter: {
          orgScenarioID: { eq: orgScenarioID },
        },
        limit: 100,
        nextToken,
      })
    )
      .then((result) => {
        if (result.data.listCronTabs.nextToken !== null)
          return queryCronTabs({
            nextToken: result.data.listCronTabs.nextToken,
            items: [...items, ...result.data.listCronTabs.items],
          });
        return [...items, ...result.data.listCronTabs.items];
      })
      .catch((error) => {
        logger.error("listCronTabs", error);
        throw error;
      });
  };
  return queryCronTabs({ nextToken, items });
};

export const createCronTab = async function createCronTab(crontabData) {
  try {
    let newCrontab = await API.graphql({
      query: mutations.createCronTab,
      variables: { input: crontabData },
    }).then((result) => result.data.createCronTab);

    logger.info("createCronTab result:", newCrontab);
    return newCrontab;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const updateCronTab = async function updateCronTab(crontabData) {
  try {
    let updatedCronTab = await API.graphql({
      query: mutations.updateCronTab,
      variables: {
        input: {
          id: crontabData.id,
          orgScenarioID: crontabData.orgScenarioID,
          schedule: crontabData.schedule,
        },
      },
    }).then((result) => result.data.updateCronTab);

    logger.info("updateCronTab result:", updatedCronTab);
    return updatedCronTab;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const deleteCronTab = async function deleteCronTab(crontabID) {
  try {
    let deletedCronTab = await API.graphql({
      query: mutations.deleteCronTab,
      variables: { input: { id: crontabID } },
    }).then((result) => result.data.deleteCronTab);

    logger.info("deleteCronTab result:", deletedCronTab);
    return deletedCronTab;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const getBpCorrPattn = ({ region, access_token }) => {
  const regionalQuery = region === "EU" ? "getBpCorrPattnEU" : "getBpCorrPattn";
  return API.graphql(
    graphqlOperation(queries[regionalQuery], { query: { access_token } })
  )

    .then((res) =>
      res?.data[regionalQuery] ? JSON.parse(res?.data[regionalQuery]) : []
    )
    .catch((error) => {
      logger.error(`getBpCorrPattn error:`, error);
      throw error;
    });
};
